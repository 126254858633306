import "core-js/modules/es.array.push.js";
import AMapLoader from '@amap/amap-jsapi-loader';
import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

import { getChargingStationList } from "@/api/business/chargingStation";
export default {
  props: ['addressData', 'type'],
  data() {
    return {
      map: null,
      marker: {},
      markers: [],
      notMarkers: [],
      mouseTool: null,
      nowArea: {},
      lng: '',
      lat: '',
      centerLngLat: [104.07318, 30.662818],
      AMap: null,
      initPath: [],
      editVisible: false,
      path: [],
      // 当前绘制的多边形经纬度数组
      polygonItem: [],
      // 地图上当前编辑的图形
      circleEditor: null,
      polyEditor: null,
      mapAddress: '',
      showSearchResult: false,
      autoCompleteComponent: null,
      placeSearchComponent: null,
      poiList: [],
      form: {
        lng: '',
        lat: '',
        radius: null,
        paths: [],
        // 围栏点坐标；
        address: '',
        areaId: '',
        id: ''
      },
      nowAreaPaths: [],
      isPark: true,
      parkList: [],
      // 当前区域所有停车区
      isNp: true,
      npList: [] // 当前区域所有禁停区
    };
  },

  methods: {
    // 获取当前区域已有停车区
    getParkAreaByArea() {
      let that = this;
      if (that.isPark) {
        getChargingStationList({
          areaId: this.form.areaId
        }).then(res => {
          //console.log("查询充电站列表------------->"+JSON.stringify(res.data))
          if (res.data.length === 0) {
            ElMessage.warning('当前区域暂无充电站!');
            return;
          }
          this.isPark = false;
          that.sprinkleSomeDots(res.data);
        });
      } else {
        // 隐藏停车区
        that.map.remove(that.parkList);
        that.parkList = [];
        this.isPark = true;
      }
    },
    //撒点方法
    sprinkleSomeDots(position) {
      let that = this;
      console.log(position);
      // var map = new window.AMap.Map('container', {
      //   resizeEnable: true,
      //   center: [104.07318, 30.662818],
      //   zoom: 13
      // });
      //map.clearMap();  // 清除地图覆盖物
      // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
      position.forEach(function (marker) {
        let result = new window.AMap.Marker({
          map: that.map,
          position: [marker.longitude, marker.latitude],
          offset: new window.AMap.Pixel(-13, -30)
        });
        // 设置鼠标划过点标记显示的文字提示
        result.setTitle(marker.name);
        that.parkList.push(result);
      });
    },
    /** 初始化搜索 */
    mapSearchInit() {
      let autoOptions = {
        input: "tipInput"
      };
      let autoCompleteComponent = new window.AMap.Autocomplete(autoOptions);
      this.autoCompleteComponent = autoCompleteComponent;
      // 注册placeSearch组件
      this.placeSearchComponent = new window.AMap.PlaceSearch({
        city: "028"
      });
    },
    //根据输入内容查询
    searchKeyWord() {
      let that = this;
      that.placeSearchComponent.search(that.mapAddress, function (status, result) {
        if (status === 'complete' && result.info === "OK") {
          that.showSearchResult = true;
          that.poiList = result.poiList.pois;
          console.log("搜索结果--------->" + JSON.stringify(result.poiList.pois));
        } else {
          that.showSearchResult = false;
          that.poiList = [];
          ElMessage.warning("没有查到结果");
        }
      });
    },
    // 选择搜索的内容
    markerResult(data) {
      let that = this;
      that.showSearchResult = false;
      that.form.address = data.address;
      that.form.lng = data.location.lng;
      that.form.lat = data.location.lat;
      that.location = data.location.lng + ',' + data.location.lat;
      // 创建一个 Marker 实例：  标记点信息
      let marker = new window.AMap.Marker({
        position: [Number(data.location.lng), Number(data.location.lat)],
        // 经纬度
        anchor: 'center',
        offset: new window.AMap.Pixel(0, 0),
        //创建一个自定义图标实例
        icon: new window.AMap.Icon({
          size: new window.AMap.Size(28, 30),
          // 图标尺寸
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
          // 自定义图像的url
          imageSize: new window.AMap.Size(27, 30) // 根据所设置的大小拉伸或压缩图片
        })
      });

      let inRing = window.AMap.GeometryUtil.isPointInRing([Number(data.location.lng), Number(data.location.lat)], this.nowAreaPaths);
      if (inRing) {
        // 取消编辑状态
        this.deleRectangle();
        this.markers.push(marker);
        this.map.add(marker);
        // 逆解析地址
        // this.toGeoCoder();
        setTimeout(() => {
          this.map.setCenter(data.location);
          this.map.setZoom(18);
        }, 50);
        let thisPosition = {
          address: this.address,
          lng: data.location.lng,
          lat: data.location.lat
        };
        this.$emit("select", thisPosition);
      } else {
        ElMessage.warning("该地点不在服务区域内，请重新选择");
      }
    },
    changeActive($event) {
      $event.currentTarget.className = "map_search_div map_search_hover";
    },
    removeActive($event) {
      $event.currentTarget.className = "map_search_div";
    },
    // 自选中心点
    markerEvent() {
      let that = this;
      that.mouseTool = new window.AMap.MouseTool(that.map);
      // 创建一个 Marker 实例：  标记点信息
      that.mouseTool.marker({
        // position: [lng, lat],   // 经纬度
        anchor: 'center',
        offset: new window.AMap.Pixel(0, 0),
        //创建一个自定义图标实例
        icon: new window.AMap.Icon({
          size: new window.AMap.Size(28, 30),
          // 图标尺寸
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
          // 自定义图像的url
          imageSize: new window.AMap.Size(27, 30) // 根据所设置的大小拉伸或压缩图片
        })
      });

      that.mouseTool.on('draw', function (event) {
        // event.obj 为绘制出来的覆盖物对象
        let inRing = window.AMap.GeometryUtil.isPointInRing([event.obj._position.lng, event.obj._position.lat], that.nowAreaPaths);
        if (inRing) {
          that.map.remove(that.markers);
          that.lng = event.obj._position.lng;
          that.lat = event.obj._position.lat;
          that.form.lng = event.obj._position.lng;
          that.form.lat = event.obj._position.lat;
          that.location = event.obj._position.lng + ',' + event.obj._position.lat;
          //只保留一个标记点
          that.markers.push(event.obj);
          // 逆解析地址
          that.toGeoCoder();
        } else {
          that.notMarkers.push(event.obj);
          that.map.remove(that.notMarkers);
          ElMessage.warning("中心点必须在服务区域内，请重新打点");
        }
      });
    },
    // 绘制服务区域
    drawArea() {
      let that = this;
      this.$api.business.electronicFence.getByAreaId({
        id: this.form.areaId
      }).then(res => {
        if (res.code === 200) {
          that.nowArea = res.data;
          if (res.data.paths) {
            let pt = [];
            res.data.paths.forEach(v => {
              pt.push([v.lng, v.lat]);
            });
            that.nowAreaPaths = pt;
            //创建多边形 Polygon 实例
            let polygon = new window.AMap.Polygon({
              path: pt,
              strokeColor: "#FF33FF",
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillOpacity: 0.4,
              fillColor: '#98FB98',
              zIndex: 50
            });
            //多边形 Polygon对象添加到 Map
            that.map.add(polygon);
            if (that.type === 'ADD' && !that.addressData.lng && !that.addressData.lat) {
              that.map.setFitView([polygon]);
            }
          }
        }
      });
    },
    // 保存
    saveInfo() {
      if (this.circleEditor) {
        this.circleEditor.close();
      }
      if (this.polyEditor) {
        this.polyEditor.close();
      }
      if (this.parkType === '定位范围' && this.form.lng === '' && this.form.lat === '') {
        ElMessage.error("未绘制点位");
      } else {
        console.log("绘制信息");
        console.log(this.form);
        this.setAddress(this.form);
        this.$emit('close', false);
        ElMessage.success("充电站已定位");
      }
    },
    // 清除
    deleRectangle() {
      let scope = this;
      // 取消编辑状态
      if (scope.polyEditor) {
        scope.polyEditor.close();
      }
      if (scope.circleEditor) {
        scope.circleEditor.close();
      }
      scope.map.remove(scope.markers);
      scope.map.remove(scope.polygonItem);
      scope.marker = {};
      scope.markers = [];
      scope.polygonItem = [];
      scope.form.paths = []; // 清空 form 中的坐标信息
      scope.form.radius = null; // 清空 form 中的坐标信息
    },

    initMap() {
      let that = this;
      console.log("初始化地图");
      AMapLoader.load({
        "key": this.$amapKey,
        // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.Geolocation', 'AMap.PlaceSearch', 'AMap.CitySearch', 'AMap.GeometryUtil', 'AMap.AutoComplete', 'AMap.ToolBar', 'AMap.PolyEditor', 'AMap.MouseTool', 'AMap.Polygon', "AMap.Geocoder", "AMap.AutoComplete", 'AMap.CircleEditor', 'AMap.Circle'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        console.log("初始化地图成功");
        that.map = new AMap.Map('container', {
          center: this.centerLngLat,
          zoom: 14,
          // mapStyle: "amap://styles/darkblue",
          pitch: 0,
          // 指南针南北方向
          rotation: 0,
          // 指南针东西方向
          // viewMode: '3D',//开启3D视图,默认为关闭
          buildingAnimation: false //楼块出现是否带动画
        });

        that.map.addControl(new AMap.Scale()); // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        that.map.addControl(new AMap.ToolBar()); //在图面添加鹰眼控件，在地图右下角显示地图的缩略图
        that.mapSearchInit();
        that.drawArea();
        //地址逆解析插件
        that.geoCoder = new AMap.Geocoder({
          city: "全国",
          //城市设为北京，默认：“全国”
          radius: 1000 //范围，默认：500
        });
        // 搜索提示插件
        that.AutoComplete = new AMap.AutoComplete({
          city: "全国"
        });
        if (this.addressData.lng && this.addressData.lat) {
          // 默认添加一个标记点
          // 创建一个 Marker 实例：  标记点信息
          let marker = new AMap.Marker({
            position: [this.lng, this.lat],
            // 经纬度
            anchor: 'center',
            offset: new AMap.Pixel(0, 0),
            //创建一个自定义图标实例
            icon: new AMap.Icon({
              size: new AMap.Size(28, 30),
              // 图标尺寸
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
              // 自定义图像的url
              imageSize: new AMap.Size(27, 30) // 根据所设置的大小拉伸或压缩图片
            })
          });

          marker.setTitle(this.addressData.add);
          //只保留一个标记点
          that.markers.push(marker);
          that.map.add(marker);
          // 缩放地图到合适的视野级别
          that.map.setFitView([marker]);
          that.form.lng = that.lng;
          that.form.lat = that.lat;
        }
      }).catch(e => {
        console.log("初始化地图失败");
        console.log(e);
      });
    },
    setAddress(address) {
      this.$emit('transfer', address); //触发transfer方法，this.user 为向父组件传递的数据
    },

    // 展示圆
    lookCircle(lng, lat, radius, isSetFitView, type) {
      //创建圆形 Circle 实例
      let circle = new window.AMap.Circle({
        center: [lng, lat],
        //圆心
        radius: radius,
        //半径
        borderWeight: 3,
        //描边的宽度
        strokeColor: "#FF33FF",
        //轮廓线颜色
        strokeOpacity: 1,
        //轮廓线透明度
        strokeWeight: 3,
        //轮廓线宽度
        fillOpacity: 0.4,
        //多边形填充透明度
        strokeStyle: "solid",
        //轮廓线样式
        strokeDasharray: [10, 10],
        fillColor: "#1791fc",
        //多边形填充颜色
        zIndex: 50 //多边形覆盖物的叠加顺序
      });
      //圆形 Circle 对象添加到 Map
      this.map.add(circle);
      //根据覆盖物范围调整视野
      if (isSetFitView) {
        this.map.setFitView([circle]);
      }
      if (!isSetFitView && type === "park") {
        this.parkList.push(circle);
      }
      if (!isSetFitView && type === "np") {
        this.npList.push(circle);
      }
    },
    // 画多边型
    lookRectangle(pt, isSetFitView, type) {
      //创建多边形 Polygon 实例
      let ptt = [];
      pt.forEach(v => {
        ptt.push([v.lng, v.lat]);
      });
      console.log(pt);
      let polygon = new window.AMap.Polygon({
        path: ptt,
        strokeColor: "#FF33FF",
        strokeWeight: 3,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50
      });
      //多边形 Polygon对象添加到 Map
      this.map.add(polygon);
      //将覆盖物调整到合适视野
      if (isSetFitView) {
        this.map.setFitView([polygon]);
      }
      if (!isSetFitView && type === "park") {
        this.parkList.push(polygon);
      }
      if (!isSetFitView && type === "np") {
        this.npList.push(polygon);
      }
    },
    // 逆解析地址
    toGeoCoder() {
      let lnglat = [this.lng, this.lat];
      this.geoCoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.form.address = result.regeocode.formattedAddress;
          console.log(this.form.address);
        }
      });
    }
  },
  created() {
    this.form.areaId = this.addressData.areaId;
    if (this.addressData.id) {
      this.form.id = this.addressData.id;
    }
    if (this.addressData.lng && this.addressData.lat && this.addressData.add) {
      this.lng = this.addressData.lng;
      this.lat = this.addressData.lat;
      this.form.address = this.addressData.add;
      this.centerLngLat = [this.lng, this.lat];
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  }
};